import styled from 'styled-components';

export const Input = styled.input`
  -webkit-appearance: none;
  border: 1px solid #bcc1c5;
  border-radius: 0;
  box-sizing: border-box;
  font-size: 14px;
  margin-top: 8px;
  outline: none;
  padding: 16px;
  width: 100%;

  &:disabled {
    background: #eee;
    pointer-events: none;
  }

  &:invalid:not(:focus):not(:placeholder-shown) {
    border-color: red;
  }

  &:focus {
    border-color: #80bdff;
    -webkit-box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    -moz-box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    -ms-box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    -o-box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    box-shadow: 0 0 0 3px rgba(0,123,255,.25);

    + p { display: none; }
  }

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  @media only screen and (min-width: 460px) {
    margin-top: 0;
    font-size: 16px;
  }
`;
