import { FormField } from '../../interfaces/FormField';
import { inputFields } from '../../labels/input';

const { CLIENTNUMBER, CLIENTNUMBER_DESCRIPTION, HOUSENUMBER, HOUSENUMBER_SUFFIX, POSTALCODE } = inputFields;

export const isInIframe = () => {
  try {
    const inIframe = window.self !== window.top;
    if (inIframe) {
      document.body.className += ' ' + 'inIframe';
    }
    return inIframe;
  } catch (e) {
    return true;
  }
};

const clientNumberField: FormField = {
  type: 'number',
  label: CLIENTNUMBER,
  label_sub: {
    text: CLIENTNUMBER_DESCRIPTION,
    link: 'https://www.ziggo.nl/klantenservice/abonnement/vind-je-klantnummer/'
  },
  min: 10000,
  max: 99999999,
  name: 'clientnumber',
  pattern: '[0-9]*',
  placeholder: '5 tot 8 cijfers',
  required: true,
  state: 'ziggoNr'
};

const houdeNumberField: FormField = {
  type: 'number',
  label: HOUSENUMBER,
  name: 'housenumber',
  pattern: '[0-9]*',
  placeholder: '3',
  required: true,
  state: 'houseNr'
};

const houseNumberSuffixField: FormField = {
  name: 'suffix',
  placeholder: 'A',
  state: 'houseNrAdditation'
};

const postalCodeField: FormField = {
  autoFocus: false, // !isInIframe(),
  label: POSTALCODE,
  maxLength: 6,
  minLength: 6,
  name: 'postal-code',
  pattern: '[1-9][0-9]{3}\s?[a-zA-Z]{2}',
  placeholder: '1000AB',
  required: true,
  state: 'postalCode'
};

export const form = {
  [CLIENTNUMBER]: clientNumberField,
  [HOUSENUMBER]: houdeNumberField,
  [HOUSENUMBER_SUFFIX]: houseNumberSuffixField,
  [POSTALCODE]: postalCodeField
};
