import styled from 'styled-components';

export const CheckMark = styled.span`
  background-color: #eee;
  border-radius: 3px;
  height: 22px;
  left: 0;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  width: 22px;
  -webkit-transition: background-color .2s linear;
  -moz-transition: background-color .2s linear;
  -ms-transition: background-color .2s linear;
  -o-transition: background-color .2s linear;
  transition: background-color .2s linear;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #FFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CheckBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:focus:not(:checked) ~ ${CheckMark} {
    -webkit-box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    -moz-box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    -ms-box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    -o-box-shadow: 0 0 0 3px rgba(0,123,255,.25);
    box-shadow: 0 0 0 3px rgba(0,123,255,.25);
  }
`;

export const CheckBoxLabel = styled.label`
  font-size: 14px;
  line-height: 175%;
  margin-top: 8px;
  padding-left: 40px;
  outline: none;

  ${CheckBox}:checked ~ ${CheckMark} {
    background-color: #F48C00;

    &:after {
      display: block;
    }
  }

  &:hover {
    ${CheckBox}:checked ~ ${CheckMark} {
      background-color: #e38301;
    }

    ${CheckBox}:not(:checked) ~ ${CheckMark} {
      background-color: #ccc;
    }
  }

  @media only screen and (min-width: 460px) {
    font-size: 16px;
  }
`;
