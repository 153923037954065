import { validationLabels } from '../../labels/validation';

const {
  INCOMPLETE_CLIENTNUMBER,
  INVALID_CLIENTNUMBER,
  INVALID_HOUSENUMBER,
  INVALID_POSTALCODE,
  REQUIRED_CLIENTNUMBER,
  REQUIRED_HOUSENUMBER,
  REQUIRED_POSTALCODE
} = validationLabels;

const isEmpty = (value: string) => value.length === 0;

const houseNumber = (houseNr: string) => {
  if (isEmpty(houseNr)) {
    return REQUIRED_HOUSENUMBER;
  } else if (isNaN(+houseNr)) {
    return INVALID_HOUSENUMBER;
  }
  return '';
};

const postalCode = (pc: string) => {
  if (isEmpty(pc)) {
    return REQUIRED_POSTALCODE;
  } else if (!pc.match(/^[0-9]{4}[A-Z]{2}$/g)) {
    return INVALID_POSTALCODE;
  }
  return '';
};

const ziggoNr = (nr: string) => {
  if (isEmpty(nr)) {
    return REQUIRED_CLIENTNUMBER;
  } else if (isNaN(+nr)) {
    return INVALID_CLIENTNUMBER;
  } else if (nr.length < 5 || nr.length > 8) {
    return INCOMPLETE_CLIENTNUMBER;
  }
  return '';
};

const list = (h: string, p: string, z: string) => {
  return [postalCode(p), houseNumber(h), ziggoNr(z)].filter(Boolean);
};

export default { houseNumber, list, postalCode, ziggoNr };
