import styled from 'styled-components';

interface LabelProp {
  hasSubLabel: boolean;
}

export const Label = styled.label`
  font-size: 14px;
  font-weight: 700;

  span, a {
    display: block;
  }

  span {
    color: #333;
    display: inline;
    font-size: 12px;

    @media only screen and (min-width: 460px) {
      display: inline-block;
      font-size: 14px;
    }
  }

  img {
    margin: 0 0 -2px 4px;
  }

  & + div { flex: 1; }

  @media only screen and (min-width: 460px) {
    font-size: 16px;
    padding-top: ${(props: LabelProp) => props.hasSubLabel ? '6px' : '17px'};
    width: 40%;
  }
`;
