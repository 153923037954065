import styled from 'styled-components';

import { isInIframe } from '../components/form/config';

const container = styled.div`
  background: #FFF;
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  max-width: ${isInIframe() ? 'auto' : '760px'};
  padding: ${isInIframe() ? '0' : '16px'};
  width: 100%;

  @media only screen and (min-width: 460px) {
    padding: ${isInIframe() ? '0' : '32px'};
  }
`;

const flex = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media only screen and (min-width: 460px) {
    flex-direction: row;

    div:first-of-type:not(:only-of-type) {
      width: calc(100% - 116px);
    }

    div:nth-of-type(2) {
      width: 100px;
      margin-left: 16px;
    }
  }
`;

export default { container, flex };
