import styled from 'styled-components';

export const Check = styled.p`
  color: #0da93b;
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(50% - 5px);
  right: 20px;

  @media only screen and (min-width: 460px) {
    top: calc(50% - 10px);
  } 
`;