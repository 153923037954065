import styled from 'styled-components';

export const Alert = styled.p`
  background: #eee;
  border-radius: 6px;
  display: block;
  margin-bottom: 8px;
  margin-top: 24px;
  padding: 16px 24px;
`;
