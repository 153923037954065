import * as React from 'react';
import { Check, Error, Input, Label } from '../../styles';
import { FormField } from '../../interfaces/FormField';
import Validate from './validation';

const externalLink = require('../../../public/external-link.svg');

interface FieldProps extends FormField {
  disabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export default class Field extends React.Component<FieldProps> {
  state = {
    error: '',
    isValid: false
  };

  validate = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    let error = '';
    if (this.props.state === 'postalCode') {
      error = Validate.postalCode(target.value);
    } else if (this.props.state === 'houseNr') {
      error = Validate.houseNumber(target.value);
    } else if (this.props.state === 'ziggoNr') {
      error = Validate.ziggoNr(target.value);
    }
    this.setState({ error: error, isValid: !error && !!target.value });
  }

  getSubLabel() {
    if (!this.props.label_sub) { return null; }

    const { text, link } = this.props.label_sub;
    if (!link) {
      return <span>{text}</span>;
    } else {
      return (
        <a href={link} target={'_blank'}>
          <span>{text}</span>
          <img src={externalLink} alt="" width="14" height="14" />
        </a>
      );
    }
  }

  onKeyPress = (event: any) => {
    if (!(this.props.type && this.props.type === 'number')) { 
      return;
    }
    if (/[^0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  render() {
    const { label, label_sub, name, state, ...rest } = this.props;

    return (
      <>
        {!!label && (
          <Label htmlFor={name} hasSubLabel={!!label_sub}>
            {label}
            {!!label_sub && this.getSubLabel()}
          </Label>
        )}
        <div>
          <Input {...rest} onKeyPress={this.onKeyPress} name={name} onBlur={this.validate} />
          {!!this.state.error && <Error>{this.state.error}</Error>}
          {this.state.isValid && <Check>&#10003;</Check>}
        </div>
      </>
    );
  }
}