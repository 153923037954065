import styled from 'styled-components';

export const Button = styled.button`
  background: #f48c00;
  border: none;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.4);
  -moz-box-shadow: 0 1px 0 rgba(0,0,0,0.4);
  -ms-box-shadow: 0 1px 0 rgba(0,0,0,0.4);
  -o-box-shadow: 0 1px 0 rgba(0,0,0,0.4);
  box-shadow: 0 1px 0 rgba(0,0,0,0.4);
  color: #FFF;
  cursor: pointer;
  font-size: 18px;
  font-family: 'DiodrumSemiBold', Arial, Helvetica, sans-serif;
  margin-top: 16px;
  outline: none;
  padding: 12px 32px;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -ms-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
  width: 145px;

  &:disabled {
    background-color: #f1f2f3;
    color: #c0c4c8;
    box-shadow: none;
  }

  &:active:not(:disabled) {
    background-color: #e88500;
    -webkit-box-shadow: inset 0 1px 0 0 rgba(0,0,0,0.4);
    -moz-box-shadow: inset 0 1px 0 0 rgba(0,0,0,0.4);
    -ms-box-shadow: inset 0 1px 0 0 rgba(0,0,0,0.4);
    -o-box-shadow: inset 0 1px 0 0 rgba(0,0,0,0.4);
    box-shadow: inset 0 1px 0 0 rgba(0,0,0,0.4);
  }

  &:focus:not(:disabled) {
    background-color: #e88500;
    -webkit-box-shadow: inset 0 0 0 1px #f6a233;
    -moz-box-shadow: inset 0 0 0 1px #f6a233;
    -ms-box-shadow: inset 0 0 0 1px #f6a233;
    -o-box-shadow: inset 0 0 0 1px #f6a233;
    box-shadow: inset 0 0 0 1px #f6a233;
  }

  &:hover:not(:disabled) {
    background-color: #f6a233;
  }

  img {
    margin: 0 0 -9px 0;
  }
`;
