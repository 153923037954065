import * as React from 'react';
import axios, { AxiosInstance } from 'axios';

import { Alert, Button, CheckBox, CheckBoxLabel, CheckMark } from '../../styles';
import Field from './Field';
import Layout from '../../styles/layout';

import { form } from './config';
import { error } from '../../labels/error';
import { inputFields } from '../../labels/input';
import Validate from './validation';

const spinner = require('../../../public/loading.gif');

const { CHECKBOX, CLIENTNUMBER, HOUSENUMBER, HOUSENUMBER_SUFFIX, POSTALCODE } = inputFields;

interface FormState {
  houseNr: string;
  houseNrAdditation: string;
  isChecked: boolean;
  loading: boolean;
  postalCode: string;
  responseError: string;
  ziggoNr: string;
}

export default class Form extends React.Component<{}, FormState> {
  alertRef: any;
  private http: AxiosInstance;

  constructor(props: {}) {
    super(props);

    this.state = {
      houseNr: '',
      houseNrAdditation: '',
      isChecked: false,
      loading: false,
      postalCode: '',
      responseError: '',
      ziggoNr: ''
    };

    this.http = axios.create({ baseURL: process.env.API_URL });
  }

  submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.toggleLoading();
    
    if (!this.isDisabled()) {
      const { houseNr, houseNrAdditation, postalCode, ziggoNr } = this.state;
      
      this.http.post('/check/ziggo', { ziggoNr, houseNr, houseNrAdditation, postalCode }).then(({ data }) => {
        const selfserviceHashCode = data ? data.selfserviceHashCode : null;
        this.toggleLoading();

        if (selfserviceHashCode) {
          window.location.href = `${process.env.SELFSERVICE_URL}/t/${selfserviceHashCode}`;
        } else {
          this.handleError({ response: { data: false }});
        }
      }).catch((err) => {
        this.handleError(err);
      }); 
    }
  }

  scrollToAlert = () => {
    setTimeout(() => this.alertRef && this.alertRef.scrollIntoView({ behavior: 'smooth' }), 100);
  }

  handleError = (err: any) => {
    this.toggleLoading();

    if (err.response.data) {
      switch (err.response.data.error) {
        case 'COMPLETED':
          return this.setState({ responseError: error.COMPLETED }, () => this.scrollToAlert());
        case 'NOT_FOUND':
          return this.setState({ responseError: error.NOT_FOUND }, () => this.scrollToAlert());
        default:
          return this.setState({ responseError: error.DEFAULT }, () => this.scrollToAlert());
      }
    } else {
      throw err;
    }
  }

  toggleCheckbox = () => this.setState({ isChecked: !this.state.isChecked });
  toggleLoading = () => this.setState({ loading: !this.state.loading });

  isDisabled = () => {
    const { houseNr, isChecked, postalCode, ziggoNr } = this.state;
    return Validate.list(houseNr, postalCode, ziggoNr).length > 0 || !isChecked;
  }

  getFormattedPostalCode = (value: string) => value.toLocaleUpperCase().replace(/\s/g, '');

  render() {
    return (
      <div>
        <form onSubmit={this.submit} noValidate={true}>
          <Layout.flex>
            <Field
              {...form[POSTALCODE]}
              disabled={this.state.loading}
              onChange={({ target }) => this.setState({ postalCode: this.getFormattedPostalCode(target.value) })}
              value={this.state.postalCode}
            />
          </Layout.flex>

          <Layout.flex>
            <Field
              {...form[HOUSENUMBER]}
              disabled={this.state.loading}
              onChange={({ target: { value } }) => this.setState({ houseNr: value })}
              value={this.state.houseNr}
            />

            <Field
              {...form[HOUSENUMBER_SUFFIX]}
              disabled={this.state.loading}
              onChange={({ target: { value } }) => this.setState({ houseNrAdditation: value })}
              value={this.state.houseNrAdditation}
            />
          </Layout.flex>

          <Layout.flex>
            <Field
              {...form[CLIENTNUMBER]}
              disabled={this.state.loading}
              onChange={({ target: { value } }) => this.setState({ ziggoNr: value })}
              value={this.state.ziggoNr}
            />
          </Layout.flex>

          <Layout.flex>
            <CheckBoxLabel htmlFor={'permission'}>
              <CheckBox id={'permission'} type={'checkbox'} checked={this.state.isChecked} onChange={this.toggleCheckbox} />
              <CheckMark />
              {CHECKBOX}
            </CheckBoxLabel>
          </Layout.flex>

          {!!this.state.responseError && (
          <Alert ref={ref => this.alertRef = ref}>
            <span dangerouslySetInnerHTML={{ __html: this.state.responseError}} />
          </Alert>
          )}

          <Button disabled={this.isDisabled()}>
            {!this.state.loading && 'Volgende'}
            {this.state.loading && <img src={spinner} alt="" width="25" height="25" />}
          </Button>
        </form>
      </div>
    );
  }
}