import * as React from 'react';

import Form from './components/form/Form';
import { isInIframe } from './components/form/config';
import Layout from './styles/layout';
import Logo from './components/Logo';

class App extends React.Component<{}> {
  render() {
    return (
      <Layout.container>
        {!isInIframe() && <Logo />}
        <h1>Plan eenvoudig je afspraak!</h1>
        <h2>Onze servicemedewerker helpt je om mee te schakelen naar digitaal.</h2>
        <p>Vul je adresgegevens in. In de volgende stap kun je dan je afspraak plannen.</p>
        <Form />
      </Layout.container>
    );
  }
}

export default App;